.modal--backdrop {
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  position: fixed;
  background: #22252d;
  opacity: 0.97;
  z-index: 1000;
}
.modal--container {
  transition: all 0.4s cubic-bezier(0.6, -0.06, 0.41, 1.22);

  z-index: 1001;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 20px 32px;
  gap: 32px;

  position: fixed;
  width: 343px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  background: #ffffff;
  box-shadow: 0px 40px 64px -24px rgba(0, 0, 0, 0.12);
  border-radius: 24px;
}
.modal--container--close {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 303px;
  height: 32px;
}
.modal--container--close--icon:hover {
  opacity: 0.5;
  cursor: pointer;
}
.modal--container--icon {
  border-radius: 50%;
  height: 128px;
  width: 128px;
  display: grid;
  place-items: center;
  transition: all 0.4s cubic-bezier(0.6, -0.06, 0.41, 1.22);
}
.modal--container--info {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 24px;

  width: 303px;
  height: 200px;
  flex: none;

  align-self: stretch;
  flex-grow: 0;
}
.modal--container--info--title {
  width: 303px;
  height: 56px;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #22252d;
  flex: none;

  align-self: stretch;
  flex-grow: 0;
}
.modal--container--info--input {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  gap: 10px;
  width: 303px;
  height: 64px;
  color: rgba(73, 78, 91, 0.2);

  border: 2px solid #f7f7f0;
  border-radius: 12px;
}
.modal--container--info--input-number {
  width: 100%;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  text-align: left;
  letter-spacing: -0.02em;
}
.modal--container--info--input-icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 12px;

  width: 85px;
  height: 32px;

  flex: none;

  flex-grow: 0;

  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;

  text-align: center;
  letter-spacing: -0.02em;

  color: #f3ba2f;
}
.modal--container--info--subtitle {
  width: 303px;
  height: 32px;

  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #7f8596;

  flex: none;

  align-self: stretch;
  flex-grow: 0;
}
.modal--container--button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 32px;
  gap: 8px;

  width: 303px;
  height: 56px;

  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;

  background: #5384ec;
  border-radius: 12px;
  flex: none;

  align-self: stretch;
  flex-grow: 0;
  transition: all 0.4s cubic-bezier(0.6, -0.06, 0.41, 1.22);
}
.modal--container--button:disabled,
.modal--container--button:hover[disabled] {
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
}
.modal--container--button:hover {
  background: rgb(83, 90, 236);
}
.modal--container--button-error {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  width: 96px;
  height: 40px;

  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  text-align: center;

  /* Neutral/01 */

  color: #ffffff;

  background: #e45f35;
  border-radius: 8px;
  transition: all 0.4s cubic-bezier(0.6, -0.06, 0.41, 1.22);
}
.modal--container--button-error:disabled,
.modal--container--button-error:hover[disabled] {
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
}
.modal--container--button-error:hover {
  background: rgb(83, 90, 236);
}
.modal--container--divider {
  width: 275px;
  height: 1px;

  background: #f3f3f0;
}
.modal--container--button-disconnect {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 32px;
  width: 205px;
  height: 56px;
  background: #fedbd6;
  border-radius: 12px;

  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  text-align: center;

  color: #d2311b;
  transition: all 0.4s cubic-bezier(0.6, -0.06, 0.41, 1.22) 0s;
}
.modal--container--button-disconnect:disabled,
.modal--container--button-disconnect:hover[disabled] {
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
}

.modal--container--button-disconnect:hover {
  border: 2px solid #d2311b;
  border-radius: 12px;
  background: white;
}
.modal--container--button-green {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 32px;
  width: 205px;
  height: 56px;
  background: rgba(106, 187, 124, 0.25);
  border-radius: 12px;

  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  text-align: center;

  color: #30b38c;
  transition: all 0.4s cubic-bezier(0.6, -0.06, 0.41, 1.22) 0s;
}

.modal--container--button-green:disabled,
.modal--container--button-green:hover[disabled] {
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
}

.modal--container--button-green:hover {
  border: 2px solid #30b38c;
  border-radius: 12px;
  background: white;
}
.modal--container--wallet {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;

  width: 275px;
  height: 44px;
}

.modal--container--wallet--title {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  letter-spacing: -0.02em;

  /* Neutral/05 */

  color: #7f8596;
}
.modal--container--wallet--address {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0px;
  gap: 8px;

  width: 275px;
  height: 24px;

  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  text-align: center;

  /* Neutral/06 */

  color: #494e5b;
}
.modal--container--wallet--connected {
  width: 12px;
  height: 12px;
  background: #30b38c;
  border-radius: 50%;
}
.modal--container--button-admin {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 32px;
  width: 205px;
  height: 56px;

  border: 2px solid rgba(127, 133, 150, 0.3);
  border-radius: 12px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  text-align: center;

  /* Neutral/08 */

  color: #22252d;
  transition: all 0.4s cubic-bezier(0.6, -0.06, 0.41, 1.22) 0s;
}
.modal--container--button-admin:hover {
  background: #22252d;
  border-radius: 12px;
  color: #fcfcf9;
}
