:root {
  --primary-color: #00b8f4;
  --secondary-color: #f8f8f8;
  --blue: #25213f;

  --font-family: "Montserrat";
  --font-family2: "League Spartan", sans-serif;
  --white: #ffffff;
  --black: #000000;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Regular.ttf");
}

.aic {
  display: flex;
  justify-content: center;
  align-items: center;
}

.anim {
  transition: 400ms cubic-bezier(0.54, 0.21, 0.18, 1.35);
}
.anim2 {
  transition: 4000ms ease-in-out;
}
.hover:hover {
  cursor: pointer;
}
.scale50:hover {
  transform: scale(1.5);
}
.scale10:hover {
  transform: scale(1.1);
}

body {
  font-family: var(--font-family);
  overflow-x: hidden;
  width: 100vw;
  transition: 400ms cubic-bezier(0.54, 0.21, 0.18, 1.35);
}
/* *::-webkit-scrollbar {
  cursor: pointer;
  width: 4px;
  height: 4px;
  box-sizing: border-box;
}
*::-webkit-scrollbar-track {
  border-radius: 3px;
  border: 1px solid transparent;
  background: transparent;
  backdrop-filter: blur(4px);
}

*::-webkit-scrollbar-thumb {
  background: #a292a4;
  width: 4px;
  height: 4px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
} */
.wave {
  animation-name: wave-animation;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
  transform-origin: 70% 70%;
  display: inline-block;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  }
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
.list--container {
  width: 100%;
  height: 100%;
  padding: 0px 32px 64px;
}
.list--title {
  width: 100%;
  justify-content: flex-start;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;

  text-align: center;

  color: #0cbbf4;
}
.list--success {
  justify-content: flex-end;
  font-weight: bold;
  font-size: 14px;
  padding: 16px 8px;
  color: #06b16a;
  border-radius: 10px;
}
.list--error {
  width: 100%;
  justify-content: flex-end;
  font-weight: bold;
  font-size: 14px;
  padding: 16px 8px;
  color: red;
}
.list--text {
  width: 100%;
  padding: 10px;
  font-weight: bold;
  font-size: 14px;
}

.table-body-col-button {
  width: 100%;
  border-radius: 10px !important;
}

.tooltip {
  position: relative;
}
.tooltip:before {
  content: attr(data-text); /* here's the magic */
  position: absolute;
  transform: translateY(-30px);
  min-width: 100px;
  padding: 5px;
  border-radius: 10px;
  background: #000;
  color: #fff;
  text-align: center;
  display: none;
  font-size: 12px;
  opacity: 0;
  transition: 0.3s opacity;
}
.tooltip:hover:before {
  opacity: 1;
  display: block;
}

.tooltip:hover:before,
.tooltip:hover:after {
  display: block;
}
.tooltip:hover:after {
  opacity: 1;
}
/* //////////////////////// */
.adminpanel-container {
  min-height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 40px;
  padding-top: 80px;
  flex-grow: 1;
  width: 100%;
}
.tabs-outer-container {
  padding: 0px 40px;
}
.tabs-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  /* max-width: 1008px; */

  background: #f3f3f0;
  border-radius: 12px;
  flex-flow: wrap;
}
.tabs-tabs {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  width: 200px;
  height: 40px;
  border-radius: 8px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #22252d;
  transition: 700ms cubic-bezier(0.54, 0.21, 0.18, 1.35);
  cursor: pointer;
}
.tabs-tabs-active {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  width: 200px;
  height: 40px;
  background: #fcfcf9;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08), inset 0px 1px 0px #ffffff;
  border-radius: 8px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #22252d;
  transition: 700ms cubic-bezier(0.54, 0.21, 0.18, 1.35);
  cursor: pointer;
}
.tabs-tabs:hover {
  opacity: 0.5;
}
.tabs-tabs-active:hover {
  opacity: 0.5;
}
.adminpanel-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  gap: 10px;
  width: clamp(300px, calc(100vw - 120px), 90vw);
  border-radius: 12px;
  margin-bottom: 80px;
}
.adminpanel-box-option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.adminpanel-box-option-name {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #22252d;
}
.adminpanel-box-option-value {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #7f8596;
}
.adminpanel-box-button {
  display: flex;
  justify-content: center;
  width: 100%;
}
.adminpanel-form {
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
}
.adminpanel-input-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;
  width: 100%;
}
.adminpanel-input-title {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #22252d;
}
.adminpanel-input-input {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  gap: 10px;

  width: 100%;
  height: 64px;

  background: #fcfcf9;

  border: 2px solid #e7e7e1;
  border-radius: 12px;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #22252d;
}
.adminpanel-input-date {
  padding: 20px;

  width: 100%;
  background: #fcfcf9;
  border: 2px solid #e7e7e1;
  border-radius: 12px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #22252d;
}
.adminpanel-input-info {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: #7f8596;
  width: 100%;
}
